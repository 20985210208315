import { ArrowMediumDown32, Mail32 } from '@packages/themes/icons';
import type { MouseEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { Accordion } from '@packages/shared/src/components/Accordion/Accordion';
import { AccordionSummary } from '@packages/shared/src/components/Accordion/AccordionSummary';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { AccordionDetails } from '@packages/shared/src/components/Accordion/AccordionDetails';
import { Stack } from '@packages/shared/src/components/Stack/Stack';
import { Link } from '@packages/shared/src/components/Link/Link';
import { messages } from '../PromotionBanner.messages';
import { PromotionBannerTestIds } from '../PromotionBannerTestIds';
import { useAssignLinkTargetBasedOnBotStatus } from '../../../hooks/useAssignLinkTargetBasedOnBotStatus';
import type { Promotion } from '../types';

export const PromotionAccordionTemplate = ({
  summaryContent,
  toggleFunction,
  detailscontent,
  isExpanded,
  defaultExpanded,
  isPaybackPromotion = false,
}: {
  summaryContent: string;
  toggleFunction?: MouseEventHandler<HTMLDivElement | HTMLAnchorElement>;
  detailscontent: JSX.Element;
  isExpanded?: boolean;
  defaultExpanded?: boolean;
  isPaybackPromotion?: Promotion['isPaybackPromotion'];
}) => {
  const { formatMessage } = useIntl();
  const config = useConfig();
  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(formatMessage(messages.newsletterSubscribeLink));

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      // INSPIRE-3604 - use transparent background to prevent sharp edges cover rounded
      // edges the uppermost wrapper defines the color
      sx={{ bgcolor: 'transparent', color: 'inherit' }}
      expanded={isExpanded ?? false}
      defaultExpanded={defaultExpanded ?? false}
    >
      <AccordionSummary
        expandIcon={
          <ArrowMediumDown32
            sx={{ color: isPaybackPromotion ? 'text.light' : 'primary.contrastText' }}
          />
        }
        sx={{
          textAlign: 'center',
          minHeight: 0,
          flexGrow: 0,
          '& > div': { flexGrow: 0, m: 0 },
          ...(config.styleVariant === 'dark' && {
            justifyContent: 'space-between',
            fontWeight: 'bold',
            textAlign: 'left',
          }),
        }}
        onClick={toggleFunction}
      >
        <span data-testid={PromotionBannerTestIds.title}>{summaryContent}</span>
      </AccordionSummary>
      <AccordionDetails data-testid={PromotionBannerTestIds.content}>
        {detailscontent}
        <Stack
          sx={{
            borderTop: '1px solid #fff',
            paddingTop: 2,
            marginTop: 4 * 0.9,
          }}
          direction={config.styleVariant === 'dark' ? 'row' : 'column'}
          spacing={2}
          alignItems="center"
        >
          <Mail32 />
          <Link
            href={finalLink}
            variant="body2"
            color="inherit"
            underline="hover"
            onClick={toggleFunction}
          >
            {formatMessage(messages.newsletterTeaserText)}
          </Link>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
