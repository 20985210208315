import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgArrowMediumDown32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M21.199 11.474a.49.49 0 0 1 .699.005l.958.958a.503.503 0 0 1 .005.7l-6.859 6.859-6.858-6.859a.491.491 0 0 1 .005-.7l.958-.958a.499.499 0 0 1 .7-.005l5.195 5.196z" className="arrow-medium-down-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgArrowMediumDown32);
export default Memo;