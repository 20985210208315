import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgMail32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M28.757 10.071a.5.5 0 1 1-.514.858l-1.9-1.14a.5.5 0 0 1 .514-.858zM17.933 3.577 23.638 7H26.5a.5.5 0 0 1 .5.5v4.816l1-.6V10.5a.5.5 0 1 1 1 0v15c0 1.383-1.116 2.5-2.504 2.5H5.504A2.498 2.498 0 0 1 3 25.5V10.48c.001-.022.004-.045.008-.068L3 10.5a.501.501 0 0 1 .243-.429L5 9.016V7.5a.5.5 0 0 1 .5-.5h2.88l5.693-3.425c1.083-.65 2.775-.65 3.86.002M28 12.882l-8.382 5.029 6.736 6.735a.5.5 0 0 1 .057.638l-.057.07a.5.5 0 0 1-.708 0l-6.911-6.912-.766.46c-1.106.663-2.833.663-3.938 0l-.767-.461-6.912 6.916a.5.5 0 1 1-.708-.707l6.736-6.74L4 12.884V25.5c0 .832.67 1.5 1.504 1.5h20.992c.835 0 1.504-.669 1.504-1.5zM26 8H6v4.917l8.545 5.127c.789.473 2.12.474 2.91 0L26 12.916zM5 10.182l-1 .6v.935l1 .6zm9.589-5.75L10.319 7h11.377l-4.277-2.566c-.769-.46-2.065-.462-2.83-.002" className="mail-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgMail32);
export default Memo;